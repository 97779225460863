import TeamMember from "./TeamMember";
import people from "./people";

function Team() {
    return (
      <section className="team" id="team">
        <div className="container">
          <div className="title-container"><h2>Rencontrez notre équipe !</h2></div>
          {people.map((x, i) => {
              return (
                <TeamMember key={'person-' + i}  member={x} number={i} />
              );
            })}
        </div>
      </section>
    );
  }
  
  export default Team;