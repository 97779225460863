import { ReactComponent as Logo } from './logo_cmp_forest.svg';

function Home({isReady}) {
    let h = window.innerHeight;
    return (
      <section style={{ height: h }} className={`home ${isReady ? 'ready' : ''}`} id="home">
        <div className="background-image">
          <div>
            <Logo/>
            <h2><span>Centre Médical</span><span>du Parc</span></h2>
            <nav>
                <ul>

                    <li><a href="#infos">Le&nbsp;centre</a></li>
                    <li><a href="#team">L'équipe</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
              </nav>
          </div>
          
        </div>
      </section>
    );
  }
  
  export default Home;