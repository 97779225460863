import BlocInfo1 from "./BlocInfo1";
import BlocInfo2 from "./BlocInfo2";

function Infos() {

    return (
      <section className="infos" id="infos">
          <div className="container">
            <div className="title-container"><h2>Visitez notre centre !</h2></div>
             <BlocInfo2/>
             <BlocInfo1/>
          </div>
      </section>
    );
  }
  
  export default Infos;