const people = [
    {
        name: 'Justine Ghiat',
        formation: 'Ostéopathe D.O',
        title: 'Gestionnaire du centre',
        diploma: 'Diplômée de l\'ULB',
        description: 'Prise en charge des lumbagos, torticolis, sciatiques, douleurs musculaires, migraines, vertiges, dysfonctions de la mâchoire, problèmes digestifs',
        specialisation: 'Spécialisation en pré/post partum et pédiatrie',
        lang: null,
        appointment: 'Rdv au 02/731.99.89 ou <a href="https://www.doctoranytime.be/d/osteopathe/justine-ghiat">par ici</a>',
        website: 'https://www.osteopatheghiat.be',
        textButton: 'En savoir plus',
        picture: 'justineghiat.jpg',
        figcaption: null,
    },
    {
        name: 'Shahin Albeheshti',
        formation: 'Kinésithérapeute',
        title: null,
        diploma: 'Diplômé de l\'ULB',
        description: 'kinésithérapeute conventionné',
        specialisation: 'Prise en charge des pathologies de type orthopédiques/traumatiques ( lombalgies, arthroses,…), sportives (tendinopathies, lésion musculaires,…) ou neurologiques ( AVC, lésion médullaires,…)',
        lang: 'Langue parlée: français.',
        appointment: 'Pour un rendez-vous ou plus d’informations: 0488408043',
        website: 'https://www.doctoranytime.be/d/kinesitherapeute/shahin-albeheshti',
        textButton: 'Prendre rendez-vous',
        picture: 'shahinalbeheshti.jpeg',
        figcaption: null,
    },
    {
        name: 'Aurélie Vital',
        formation: 'Psychologue clinicienne',
        title: null,
        diploma: null,
        description: 'Psychologie intégrative. Consultations pour <b>enfants, adolescents et adultes</b>.',
        specialisation: 'Troubles anxieux, dépression, burn-out, difficultés professionnelles ou scolaires, harcèlement, questions existentielles, difficultés émotionnelles et relationnelles.',
        lang: null,
        appointment: 'Pour un rendez-vous ou plus d’informations: 0456.10.14.10. Le vendredi de 9h à 19h',
        website: 'https://www.doctoranytime.be/d/psychologue/aurelie-vital',
        textButton: 'Prendre rendez-vous',
        picture: 'aurelievital.jpg',
        figcaption: null,
    },
    {
        name: 'Ida Stany',
        formation: 'Médecin/Anesthésiste - Diplomée de l\'ULB, Hypnothérapeute - Diplomée de l\'UCL/INH',
        title: 'Partiellement conventionnée',
        diploma: null,
        description: 'Consultations pour <b>enfants, adolescents et adultes</b>.',
        specialisation: 'Sevrage tabagique, préparation aux interventions chirurgicales, gestion de la douleur aiguë et chronique, hypnose périnatale, technique d’activation de la conscience (TAC), apprentissage de l’autohypnose.',
        lang: null,
        appointment: 'Pour un rendez-vous ou plus d’informations: 0496.89.54.41. Le samedi de 14h à 20h',
        website: null,
        textButton: null,
        picture: 'idastany.jpg',
        figcaption: null,
    },
    {
        name: 'Dorian Dermience',
        formation: 'Ostéopathe D.O',
        title: null,
        diploma: 'Diplômé de l\'ULB',
        description: 'Prise en charge des douleurs et limitations fonctionnelles telles que les lombalgies, dorsalgies, cervicalgies, torticolis, maux de tête, sciatiques...',
        specialisation: null,
        lang: null,
        appointment: 'Pour un rendez-vous ou toute question, veuillez contacter le 0491/19.84.94.',
        website: null,
        textButton: null,
        picture: 'doriandermience.jpg',
        figcaption: null,
    },
    {
        name: 'Catherine Lemage',
        formation: 'Psychologue clinicienne',
        title: null,
        diploma: null,
        description: 'Traite: Symptômes post-traumatiques, anxiété, burnout, dépression, hypersensibilité',
        specialisation: 'Psychothérapeute spécialisée en thérapie brève. Pratique du brainspotting',
        lang: null,
        appointment: 'Prise de rdv par appel au 0487/98.51.26 (laisser un message vocal), sms ou whatsapp. Tarif: 70 euros/h (paiement cash ou payconiq). Durée de la séance: 1h. Toute séance non décommandée au minimum 24h avant le rdv sera entièrement due. Pour Adultes.',
        website: null,
        textButton: null,
        picture: 'catherinelemage.jpg',
        figcaption: null,
    },
    {
        name: 'Sarah Marlier',
        formation: 'Infirmière',
        title: null,
        diploma: null,
        description: 'Soins infirmiers généraux, drainage lymphatique, maderotherapie, hijama/ventousothérapie.',
        specialisation: null,
        lang: null,
        appointment: 'Pour un rendez-vous ou plus d’informations, veuillez contacter le 0485/43.07.04.',
        website: null,
        textButton: null,
        picture: 'sarahmarlier.jpg',
        figcaption: null,
    },
    {
        name: 'Et si c\'était vous?',
        formation: 'Notre équipe est entrain de s\'agrandir !',
        title: null,
        diploma: null,
        description: 'Si tu recherches un cabinet et que tu veux rejoindre notre équipe <br/> n’hésite pas à envoyer ta candidature à jghiat@gmail.com <br/>ou via le bouton ci-dessous.',
        specialisation: null,
        lang: null,
        appointment: null,
        website: 'mailto:jghiat@gmail.com',
        textButton: 'Postuler',
        picture: 'new.jpg',
        figcaption: 'Photo by Clay Banks on Unsplash',
    }
]

export default people;