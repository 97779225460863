function BlocInfo1() {
    return (
      <section className="bloc-info-1">
          <div className="txt-container">
              <div>
              <h3>Bienvenue dans le tout nouveau <br/>Centre Médical du Parc.</h3>
              <p style={{'fontSize': '16px'}}>Des cabinets complètements rénovés où vous accueillent des professionnels de la santé expérimentés <br/>pour vous proposer une prise en charge adaptée et personnalisée.</p>
            </div>
          </div>
          <div  className="img-container">
              <div className="first-img">
                  <img src="pictures/cabinet-22.jpeg" alt="Cabinet d'Ostéopathie du centre médical du parc à Forest, Bruxelles"/>
              </div>
          </div>
      </section>
    );
  }
  
  export default BlocInfo1;