import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Infos from "./components/Infos";
import Menu from "./components/Menu";
import Team from "./components/Team";
import Loader from './components/Loader';
import Blog from './components/Blog';


function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [scroll, setScroll] = useState('hidden');
  document.body.style.overflowY = scroll;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setScroll('scroll');
    }, 2000);
  }, []);

  return (
    <div>
      {isLoading && <Loader/>}
      <Home isReady={!isLoading}></Home>
      <Infos></Infos>
      <Team></Team>
      <Contact></Contact>
      <Menu></Menu>
      <Footer></Footer>
    </div>
  );
}

export default App;
