import { useRef, useEffect} from 'react';
import { ReactComponent as Logo } from './logo_cmp_forest.svg';
import { RiTeamFill, RiHomeHeartFill, RiMapPin2Fill } from 'react-icons/ri';


function Menu() {
    const burgerMenu = useRef();
    const menu = useRef();
    const header = useRef();
    let w = window.innerWidth;
    let h = window.innerHeight;
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > (h - 115)) {
            header.current.classList.remove('reduced');
            }else{
                header.current.classList.add('reduced');
            }
        });
    }, [h]);

    setTimeout(()=>{
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
          anchor.addEventListener('click', (e) => {
            e.preventDefault();
            let topSection = document.querySelector(e.target.getAttribute('href')).offsetTop - 105;
            
            window.scrollTo({
              top:topSection,
              behavior: "smooth"
            })
            if(w < 992){
                document.getElementById('nav').classList.remove('open');
                document.getElementById('burger-menu').classList.remove('open');
            } 
          });
        });
      }, 100)

      window.addEventListener('resize', () => {
        w = window.innerWidth;
      });

    function toggleMenu(){
        burgerMenu.current.classList.toggle('open');
        menu.current.classList.toggle('open');
    }
    return (
      <header className="menu reduced" ref={header}>
          <div className="container">
            <a href="#home" className="logo-container">
                <Logo/>
                <h1><span>Centre Médical</span><span>du Parc</span></h1>
            </a>
            <nav id='nav' ref={menu}>
                <ul>

                    <li><a href="#infos"><RiHomeHeartFill size={20} /><span>Le centre</span></a></li>
                    <li><a href="#team"><RiTeamFill size={20}/><span>L'équipe</span></a></li>
                    <li><a href="#contact"><RiMapPin2Fill size={20}/><span>Contact</span> </a></li>
                </ul>
                <div className="burger-menu" id='burger-menu' ref={burgerMenu} >
                    <div onClick={toggleMenu}>
                        <span className="line first"></span>
                        <span className="line middle"></span>
                        <span className="line last"></span>
                    </div>
                </div>
            </nav>
            
        </div>
      </header>
    );
  }
  
  export default Menu;