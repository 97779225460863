import Button from "./Button";

function TeamMember({member, number }) {
    return (
      <section className={`${number%2 === 0 ? 'odd' : 'even'} team-member`}>
 
        <div className="img-container">
          {member.figcaption && 
          <figure>
            <img src={'pictures/' + member.picture} alt={member.name}/>
            <figcaption>&copy; {member.figcaption}</figcaption>
          </figure>}
          {!member.figcaption && <img src={'pictures/' + member.picture} alt={member.name + ' - ' + member.title}/>}
            
        </div>
        <div className="txt-container">
            <div>
                <h3>{member.name}</h3>
                {member.title && <p><i>{member.title}</i></p>}
                <p>{member.formation && <span>{member.formation}</span>}{member.formation && member.diploma ? ' - ' : ''}{member.diploma && <span>{member.diploma}</span>}</p>
                <p dangerouslySetInnerHTML={{ __html:member.description }}></p>
                {member.specialisation && <p><b>{member.specialisation}</b></p>}
                {member.lang && <p>{member.lang}</p>}
                {member.appointment && <h4 dangerouslySetInnerHTML={{ __html:member.appointment }}></h4>}
                {member.website && <Button link={member.website} content={member.textButton}></Button>}
            </div>
        </div>


      </section>
    );
  }
  
  export default TeamMember;