function Footer() {
    return (
      <footer className="footer">
          <div className="container">
            <p>© 2022 , Centre médical du Parc - Forest</p>
            <p><a href="https://marinehansart.be" alt="Site web de Marine Hansart">Made by Marine Hansart</a></p>
        </div>
      </footer>
    );
  }
  
  export default Footer;