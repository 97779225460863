import { RiFacebookBoxFill, RiInstagramFill } from 'react-icons/ri';

function Contact() {
    return (
      <section className="contact" id="contact">
        <div className="container">
          <div className="title-container"><h2>Restons en contact !</h2></div>
          </div>
          <iframe title="Situation du Centre Médical du Parc" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.9504148912956!2d4.32869101562991!3d50.81355737952717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c449989bb665%3A0xe02ef1f86f91bdf5!2sAv.%20Victor%20Rousseau%20140%2C%201190%20Forest!5e0!3m2!1sfr!2sbe!4v1650527633236!5m2!1sfr!2sbe" allowFullScreen="" loading="lazy"></iframe>
          <div className="container">
            <div className="address-container">
              <h3><span>Centre Médical du Parc</span><span>Avenue Victor rousseau 140</span>
              <span> 1190, Forest</span></h3>
            </div>
          {/* <div className="map-container">
            <a href="#home" alt="Page Facebook">
              <RiFacebookBoxFill size={42}/>
            </a>
            <a href="#home" alt="Page Facebook">
              <RiInstagramFill size={40}/>
            </a>
          </div> */}
        
        </div>
      </section>
    );
  }
  
  export default Contact;