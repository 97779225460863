function Loader() {
    let h = window.innerHeight;
    let w = window.innerWidth;

    return (
      <section className={`loader`} style={{ height: h, width: w }}>
          <div className="dot dot-1"></div>
          <div className="dot dot-2"></div>
          <div className="dot dot-3"></div>
      </section>
    );
  }
  
  export default Loader;