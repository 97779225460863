function BlocInfo2() {
    return (
      <section className="bloc-info-2">
        <div className="img-container">
            <div>
                <img src="pictures/cabinet-25.jpeg" alt="Cabinet d'Ostéopathie du centre médical du Parc, à Forest, Bruxelles" />  
            </div>
            <div>
                <img src="pictures/cabinet-2.jpeg" alt="Décoration du centre médical du Parc, à Forest, Bruxelles" />  
            </div>
            <div>
                <img src="pictures/cabinet-19.jpeg" alt="Cabinet de sophrologue et psychologue à Forest, Bruxelles." />  
            </div>
        </div>
        <div className="txt-container">
            <h3>À Forest, à 2 pas de l’altitude 100 et du parc Duden, <br/> proche des transports en commun. <br/>(arrêt Mystère)</h3>
        </div>


      </section>
    );
  }
  
  export default BlocInfo2;